import React, { memo } from 'react';

import { api } from 'api';

import css from './Icons.module.css';
import { gameIcons, withPrefix } from './utils';

type ImageAttributes = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'>;
export interface CDNPictureProps extends ImageAttributes {
  /**
   * Image path without format
   * @example '/icons/wow-16@2x'
   */
  path: string;
  size: number;
  forceMimeType?: 'png' | 'webp';
}
const CDNPicture = memo<CDNPictureProps>(
  ({ path, size, forceMimeType, ...props }) => {
    if (!path) {
      return null;
    }

    if (forceMimeType !== undefined) {
      return (
        <img
          height={props.height || size}
          src={withPrefix(`${path}-${size}@2x.${forceMimeType}`)}
          style={{ flexShrink: 0 }}
          width={props.width || size}
          {...props}
        />
      );
    }

    return (
      <picture style={{ display: 'flex', flexShrink: 0 }}>
        <source srcSet={withPrefix(`${path}-${size}@2x.avif`)} type="image/avif" />
        <source srcSet={withPrefix(`${path}-${size}@2x.webp`)} type="image/webp" />
        <source srcSet={withPrefix(`${path}-${size}@2x.png`)} type="image/png" />
        <img
          height={props.height || size}
          style={{ flexShrink: 0 }}
          width={props.width || size}
          {...props}
        />
      </picture>
    );
  },
);

export interface CDNIconProps extends ImageAttributes {
  // CDN available sizes
  size: 16 | 24 | 32 | 40 | 128;
  forceMimeType?: 'png' | 'webp';
}

interface GameIconProps extends Omit<CDNPictureProps, 'path'> {
  gameId: api.GameId;
}
export const GameNextIcon = memo<GameIconProps>(({ gameId, size, ...props }) => {
  gameId = gameId;

  if (!gameIcons[gameId]) {
    return <span {...props}>{gameId}</span>;
  }

  return (
    <div {...props}>
      <img
        className={css.nextImage}
        src={withPrefix(`${gameIcons[gameId]}-${size}@2x.webp`)}
      />
    </div>
  );
});

interface GameIconProps extends Omit<CDNPictureProps, 'path'> {
  gameId: api.GameId;
}
export const GameIcon = memo<GameIconProps>(({ gameId, ...props }) => {
  gameId = gameId;

  return <CDNPicture path={gameIcons[gameId]} {...props} />;
});
export const GameWowIcon = memo<CDNIconProps>(props => (
  <GameIcon alt="WoW icon" gameId={api.GameId.WorldOfWarcraft} {...props} />
));
export const GameHearthstoneIcon = memo<CDNIconProps>(props => (
  <GameIcon alt="Hearthstone icon" gameId={api.GameId.Hearthstone} {...props} />
));
export const GameLolIcon = memo<CDNIconProps>(props => (
  <GameIcon alt="LoL icon" gameId={api.GameId.LeagueOfLegends} {...props} />
));
export const GameWarzoneIcon = memo<CDNIconProps>(props => (
  <GameIcon alt="Warzone icon" gameId={api.GameId.Warzone} {...props} />
));
export const GameLostarkIcon = memo<CDNIconProps>(props => (
  <GameIcon alt="Lostark icon" gameId={api.GameId.LostArkEn} {...props} />
));
export const GameCsgoIcon = memo<CDNIconProps>(props => (
  <GameIcon alt="CS:GO icon" gameId={api.GameId.Csgo} {...props} />
));
export const WorldOfWarcraftBurningCrusadeIcon = memo<CDNIconProps>(props => (
  <GameIcon
    alt="WoW Burning Crusade icon"
    gameId={api.GameId.WorldOfWarcraftBurningCrusade}
    {...props}
  />
));
export const ApexLegendsIcons = memo<CDNIconProps>(props => (
  <GameIcon alt="Apex Legend icon" gameId={api.GameId.ApexLegends} {...props} />
));

export const WowDamageIcon = memo<CDNIconProps>(props => {
  return <CDNPicture alt="WoW damage icon" path="/wow/dmg" {...props} />;
});
export const WowHealIcon = memo<CDNIconProps>(props => {
  return <CDNPicture alt="WoW healer icon" path="/wow/heal" {...props} />;
});
export const WowTankIcon = memo<CDNIconProps>(props => {
  return <CDNPicture alt="WoW tank icon" path="/wow/tank" {...props} />;
});
interface WowRoleIconProps extends Omit<CDNIconProps, 'path'> {
  role: api.Maybe<api.WowRole>;
  forceMimeType?: 'png' | 'webp';
}
export const WowRoleIcon = memo<WowRoleIconProps>(({ role, ...props }) => {
  if (!role) {
    return null;
  }
  switch (role) {
    case api.WowRole.Damage:
      return <WowDamageIcon {...props} />;
    case api.WowRole.Healer:
      return <WowHealIcon {...props} />;
    case api.WowRole.Tank:
      return <WowTankIcon {...props} />;
  }
});

interface WowClassIconProps extends Omit<CDNIconProps, 'path'> {
  wowClass: api.Maybe<api.WowClass>;
  forceMimeType?: 'png' | 'webp';
}

export const WowClassIcon = memo<WowClassIconProps>(({ wowClass, ...props }) => {
  if (!wowClass) {
    return null;
  }

  switch (wowClass) {
    case api.WowClass.Paladin:
      return <WowPaladinIcon {...props} />;
    case api.WowClass.DeathKnight:
      return <WowDeathKnightIcon {...props} />;
    case api.WowClass.DemonHunter:
      return <WowDemonHunterIcon {...props} />;
    case api.WowClass.Druid:
      return <WowDruidIcon {...props} />;
    case api.WowClass.Hunter:
      return <WowHunterIcon {...props} />;
    case api.WowClass.Mage:
      return <WowMageIcon {...props} />;
    case api.WowClass.Monk:
      return <WowMonkIcon {...props} />;
    case api.WowClass.Priest:
      return <WowPriestIcon {...props} />;
    case api.WowClass.Rogue:
      return <WowRogueIcon {...props} />;
    case api.WowClass.Shaman:
      return <WowShamanIcon {...props} />;
    case api.WowClass.Warlock:
      return <WowWarlockIcon {...props} />;
    case api.WowClass.Warrior:
      return <WowWarriorIcon {...props} />;
  }
});

interface WowCovenantIconProps extends Omit<CDNIconProps, 'path'> {
  wowCovenant: api.Maybe<api.WowCovenant>;
  forceMimeType?: 'png' | 'webp';
}

export const WowCovenantIcon = memo<WowCovenantIconProps>(
  ({ wowCovenant, ...props }) => {
    if (!wowCovenant) {
      return null;
    }

    switch (wowCovenant) {
      case api.WowCovenant.Kyrian:
        return <WowKyrianIcon {...props} />;
      case api.WowCovenant.Necrolord:
        return <WowNecrolordIcon {...props} />;
      case api.WowCovenant.NightFae:
        return <WowNightFaeIcon {...props} />;
      case api.WowCovenant.Venthyr:
        return <WowVenthyrIcon {...props} />;
    }
  },
);

export const WowHordeIcon = memo<CDNIconProps>(props => {
  return <CDNPicture alt="WoW horde icon" path="/wow/horde" {...props} />;
});
export const WowAllianceIcon = memo<CDNIconProps>(props => {
  return <CDNPicture alt="WoW alliance icon" path="/wow/alliance" {...props} />;
});

export const WowPaladinIcon = memo<CDNIconProps>(props => {
  return <CDNPicture alt="WoW paladin icon" path="/wow/class-paladin" {...props} />;
});
export const WowWarlockIcon = memo<CDNIconProps>(props => {
  return <CDNPicture alt="WoW warlock icon" path="/wow/class-warlock" {...props} />;
});
export const WowPriestIcon = memo<CDNIconProps>(props => {
  return <CDNPicture alt="WoW priest icon" path="/wow/class-priest" {...props} />;
});
export const WowHunterIcon = memo<CDNIconProps>(props => {
  return <CDNPicture alt="WoW hunter icon" path="/wow/class-hunter" {...props} />;
});
export const WowWarriorIcon = memo<CDNIconProps>(props => {
  return <CDNPicture alt="WoW warrior icon" path="/wow/class-warrior" {...props} />;
});
export const WowRogueIcon = memo<CDNIconProps>(props => {
  return <CDNPicture alt="WoW rogue icon" path="/wow/class-rogue" {...props} />;
});
export const WowShamanIcon = memo<CDNIconProps>(props => {
  return <CDNPicture alt="WoW shaman icon" path="/wow/class-shaman" {...props} />;
});
export const WowMageIcon = memo<CDNIconProps>(props => {
  return <CDNPicture alt="WoW mage icon" path="/wow/class-mage" {...props} />;
});
export const WowMonkIcon = memo<CDNIconProps>(props => {
  return <CDNPicture alt="WoW monk icon" path="/wow/class-monk" {...props} />;
});
export const WowDruidIcon = memo<CDNIconProps>(props => {
  return <CDNPicture alt="WoW druid icon" path="/wow/class-druid" {...props} />;
});
export const WowDemonHunterIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture alt="WoW demonHunter icon" path="/wow/class-demonHunter" {...props} />
  );
});
export const WowDeathKnightIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture alt="WoW deathKnight icon" path="/wow/class-deathKnight" {...props} />
  );
});

export const WowKyrianIcon = memo<CDNIconProps>(props => {
  return <CDNPicture alt="WoW Kyrian icon" path="/wow/covenant-kyrian" {...props} />;
});
export const WowVenthyrIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture alt="WoW Venthyr icon" path="/wow/covenant-venthyr" {...props} />
  );
});
export const WowNecrolordIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture alt="WoW Necrolord icon" path="/wow/covenant-necrolord" {...props} />
  );
});
export const WowNightFaeIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture alt="WoW NightFae icon" path="/wow/covenant-nightFae" {...props} />
  );
});

interface WowClassIconProps extends Omit<CDNIconProps, 'path'> {
  wowClass: api.Maybe<api.WowClass>;
  forceMimeType?: 'png' | 'webp';
}

interface LolTierIconProps extends Omit<CDNIconProps, 'path'> {
  rank: api.Maybe<api.LolRank>;
  forceMimeType?: 'png' | 'webp';
}

export const LolTierIcon = memo<LolTierIconProps>(({ rank, ...props }) => {
  if (!rank) {
    return null;
  }

  switch (rank) {
    case api.LolRank.Iron1:
    case api.LolRank.Iron2:
    case api.LolRank.Iron3:
    case api.LolRank.Iron4:
      return <WildRiftIronEmblem {...props} />;
    case api.LolRank.Bronze1:
    case api.LolRank.Bronze2:
    case api.LolRank.Bronze3:
    case api.LolRank.Bronze4:
      return <WildRiftBronzeEmblem {...props} />;
    case api.LolRank.Silver1:
    case api.LolRank.Silver2:
    case api.LolRank.Silver3:
    case api.LolRank.Silver4:
      return <WildRiftSilverEmblem {...props} />;
    case api.LolRank.Gold1:
    case api.LolRank.Gold2:
    case api.LolRank.Gold3:
    case api.LolRank.Gold4:
      return <WildRiftGoldEmblem {...props} />;
    case api.LolRank.Platinum1:
    case api.LolRank.Platinum2:
    case api.LolRank.Platinum3:
    case api.LolRank.Platinum4:
      return <WildRiftPlatinumEmblem {...props} />;
    case api.LolRank.Emerald1:
    case api.LolRank.Emerald2:
    case api.LolRank.Emerald3:
    case api.LolRank.Emerald4:
      return <WildRiftEmeraldEmblem {...props} />;
    case api.LolRank.Diamond1:
    case api.LolRank.Diamond2:
    case api.LolRank.Diamond3:
    case api.LolRank.Diamond4:
      return <WildRiftDiamondEmblem {...props} />;
    case api.LolRank.Master:
      return <WildRiftMasterEmblem {...props} />;
    case api.LolRank.Grandmaster:
      return <WildRiftGrandmasterEmblem {...props} />;
    case api.LolRank.Challenger:
      return <WildRiftChallengerEmblem {...props} />;
    case api.LolRank.None:
      return null;
  }
});

export const LolRankIcon = memo<LolTierIconProps>(({ rank, ...props }) => {
  if (!rank) {
    return null;
  }

  switch (rank) {
    case api.LolRank.Iron1:
    case api.LolRank.Iron2:
    case api.LolRank.Iron3:
    case api.LolRank.Iron4:
      return <LolRankIron {...props} />;
    case api.LolRank.Bronze1:
    case api.LolRank.Bronze2:
    case api.LolRank.Bronze3:
    case api.LolRank.Bronze4:
      return <LolRankBronze {...props} />;
    case api.LolRank.Silver1:
    case api.LolRank.Silver2:
    case api.LolRank.Silver3:
    case api.LolRank.Silver4:
      return <LolRankSilver {...props} />;
    case api.LolRank.Gold1:
    case api.LolRank.Gold2:
    case api.LolRank.Gold3:
    case api.LolRank.Gold4:
      return <LolRankGold {...props} />;
    case api.LolRank.Platinum1:
    case api.LolRank.Platinum2:
    case api.LolRank.Platinum3:
    case api.LolRank.Platinum4:
      return <LolRankPlatinum {...props} />;
    case api.LolRank.Diamond1:
    case api.LolRank.Diamond2:
    case api.LolRank.Diamond3:
    case api.LolRank.Diamond4:
      return <LolRankDiamond {...props} />;
    case api.LolRank.Master:
      return <LolRankMaster {...props} />;
    case api.LolRank.Grandmaster:
      return <LolRankGrandmaster {...props} />;
    case api.LolRank.Challenger:
      return <LolRankChallenger {...props} />;
    case api.LolRank.None:
    case api.LolRank.Emerald1:
    case api.LolRank.Emerald2:
    case api.LolRank.Emerald3:
    case api.LolRank.Emerald4:
      return null;
  }
});

export const WildRiftIronEmblem = memo<CDNIconProps>(props => {
  return (
    <CDNPicture alt="WildRift Iron Emblem" path="/wildrift/emblem-iron" {...props} />
  );
});

export const WildRiftGrandmasterEmblem = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="WildRift Grandmaster Emblem"
      path="/wildrift/emblem-grandmaster"
      {...props}
    />
  );
});
export const WildRiftGoldEmblem = memo<CDNIconProps>(props => {
  return (
    <CDNPicture alt="WildRift Gold Emblem" path="/wildrift/emblem-gold" {...props} />
  );
});
export const WildRiftDiamondEmblem = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="WildRift Diamond Emblem"
      path="/wildrift/emblem-diamond"
      {...props}
    />
  );
});
export const WildRiftChallengerEmblem = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="WildRift Challenger Emblem"
      path="/wildrift/emblem-challenger"
      {...props}
    />
  );
});
export const WildRiftBronzeEmblem = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="WildRift Bronze Emblem"
      path="/wildrift/emblem-bronze"
      {...props}
    />
  );
});
export const WildRiftSilverEmblem = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="WildRift Silver Emblem"
      path="/wildrift/emblem-silver"
      {...props}
    />
  );
});
export const WildRiftPlatinumEmblem = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="WildRift Platinum Emblem"
      path="/wildrift/emblem-platinum"
      {...props}
    />
  );
});
export const WildRiftEmeraldEmblem = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="WildRift Emerald Emblem"
      path="/wildrift/emblem-emerald"
      {...props}
    />
  );
});
export const WildRiftMasterEmblem = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="WildRift Master Emblem"
      path="/wildrift/emblem-master"
      {...props}
    />
  );
});
export const LolRankBronze = memo<CDNIconProps>(props => {
  return <CDNPicture alt="Lol Bronze rank" path="/lol/Bronze" {...props} />;
});
export const LolRankChallenger = memo<CDNIconProps>(props => {
  return <CDNPicture alt="Lol Challenger rank" path="/lol/Challenger" {...props} />;
});
export const LolRankDiamond = memo<CDNIconProps>(props => {
  return <CDNPicture alt="Lol Diamond rank" path="/lol/Diamond" {...props} />;
});
export const LolRankGold = memo<CDNIconProps>(props => {
  return <CDNPicture alt="Lol Gold rank" path="/lol/Gold" {...props} />;
});
export const LolRankGrandmaster = memo<CDNIconProps>(props => {
  return <CDNPicture alt="Lol Grandmaster rank" path="/lol/Grandmaster" {...props} />;
});
export const LolRankIron = memo<CDNIconProps>(props => {
  return <CDNPicture alt="Lol Iron rank" path="/lol/Iron" {...props} />;
});
export const LolRankMaster = memo<CDNIconProps>(props => {
  return <CDNPicture alt="Lol Master rank" path="/lol/Master" {...props} />;
});
export const LolRankPlatinum = memo<CDNIconProps>(props => {
  return <CDNPicture alt="Lol Platinum rank" path="/lol/Platinum" {...props} />;
});
export const LolRankSilver = memo<CDNIconProps>(props => {
  return <CDNPicture alt="Lol Silver rank" path="/lol/Silver" {...props} />;
});

export const LostArkBerserkerIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="LostArk Berserker class"
      path="/lostark/class-berserker"
      {...props}
    />
  );
});
export const LostArkPaladinIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="LostArk Paladin class"
      path="/lostark/class-paladin"
      {...props}
    />
  );
});
export const LostArkGunlancerIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="LostArk Gunlancer class"
      path="/lostark/class-gunlancer"
      {...props}
    />
  );
});
export const LostArkStrikerIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="LostArk Striker class"
      path="/lostark/class-striker"
      {...props}
    />
  );
});
export const LostArkWardancerIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="LostArk Wardancer class"
      path="/lostark/class-wardancer"
      {...props}
    />
  );
});
export const LostArkScrapperIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="LostArk Scrapper class"
      path="/lostark/class-scrapper"
      {...props}
    />
  );
});
export const LostArkSoulfistIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="LostArk Soulfist class"
      path="/lostark/class-soulfist"
      {...props}
    />
  );
});
export const LostArkGlaivierIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="LostArk Glaivier class"
      path="/lostark/class-glaivier"
      {...props}
    />
  );
});
export const LostArkGunslingerIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="LostArk Gunslinger class"
      path="/lostark/class-gunslinger"
      {...props}
    />
  );
});
export const LostArkMachinistIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="LostArk Machinist class"
      path="/lostark/class-machinist"
      {...props}
    />
  );
});
export const LostArkDestroyerIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="LostArk Destroyer class"
      path="/lostark/class-destroyer"
      {...props}
    />
  );
});
export const LostArkArtilleristIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="LostArk Artillerist class"
      path="/lostark/class-artillerist"
      {...props}
    />
  );
});
export const LostArkDeadeyeIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="LostArk Deadeye class"
      path="/lostark/class-deadeye"
      {...props}
    />
  );
});
export const LostArkSharpshooterIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="LostArk Sharpshooter class"
      path="/lostark/class-sharpshooter"
      {...props}
    />
  );
});
export const LostArkBardIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture alt="LostArk Bard class" path="/lostark/class-bard" {...props} />
  );
});
export const LostArkAecanistIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="LostArk Arcanist class"
      path="/lostark/class-arcanist"
      {...props}
    />
  );
});
export const LostArkSummonerIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="LostArk Summoner class"
      path="/lostark/class-summoner"
      {...props}
    />
  );
});
export const LostArkSorceressIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="LostArk Sorceress class"
      path="/lostark/class-sorceress"
      {...props}
    />
  );
});
export const LostArkShadowhunterIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="LostArk Shadowhunter class"
      path="/lostark/class-shadowhunters"
      {...props}
    />
  );
});
export const LostArkDeathbladeIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture
      alt="LostArk Deathblade class"
      path="/lostark/class-deathblade"
      {...props}
    />
  );
});
export const LostArkReaperIcon = memo<CDNIconProps>(props => {
  return (
    <CDNPicture alt="LostArk Reaper class" path="/lostark/class-reaper" {...props} />
  );
});

interface LostArkClassIconProps extends Omit<CDNIconProps, 'path'> {
  lostArkClass: api.Maybe<api.LostArkArenaClass>;
  forceMimeType?: 'png' | 'webp';
}

const LOST_ARK_CLASS_IMG: Record<api.LostArkArenaClass, string> = {
  Arcanist:
    'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/arcanist.webp',
  Summoner:
    'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/summoner.webp',
  Artillerist:
    'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/artillerist.webp',
  Bard: 'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/bard.webp',
  Berserker:
    'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/berserker.webp',
  Deathblade:
    'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/deathblade.webp',
  Reaper: 'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/reaper.webp',
  Destroyer:
    'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/destroyer.webp',
  Deadeye:
    'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/deadeye.webp',
  Glaivier:
    'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/glaivier.webp',
  Gunslinger:
    'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/gunslinger.webp',
  Machinist:
    'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/machinist.webp',
  Gunlancer:
    'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/gunlancer.webp',
  Paladin:
    'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/paladin.webp',
  Soulfist:
    'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/soulfist.webp',
  Shadowhunter:
    'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/shadowhunter.webp',
  Sharpshooter:
    'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/sharpshooter.webp',
  Scrapper:
    'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/scrapper.webp',
  Sorceress:
    'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/sorceress.webp',
  Striker:
    'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/striker.webp',
  Wardancer:
    'https://storage.googleapis.com/lfgcdn/assets/lostark/classes/wardancer.webp',
};

export const LostArkClassIcon = memo<LostArkClassIconProps>(
  ({ lostArkClass, ...props }) => {
    if (!lostArkClass) {
      return null;
    }

    return (
      <img
        height={props.height || props.size}
        src={LOST_ARK_CLASS_IMG[lostArkClass]}
        style={{ flexShrink: 0 }}
        width={props.width || props.size}
        {...props}
      />
    );
  },
);
