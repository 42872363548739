import type { api } from 'api';

const cdnPath = 'https://storage.googleapis.com/lfgcdn/assets';

export const withPrefix = (path: string): string => cdnPath + path;

export const gameIcons: Record<api.GameId, string> = {
  // TODO: @fix conflict
  WorldOfWarcraft: '/icons/wow',
  WorldOfWarcraftBurningCrusade: '/icons/wow-burning-crusade',
  Hearthstone: '/icons/hs',
  Overwatch: '/icons/overwatch',
  PUBG: '/icons/pubg',
  PUBGMobile: '/icons/pubg-mobile-official',
  CODMobile: '/icons/cod-mobile',
  Standoff2: '/icons/standoff',
  GooseGooseDuck: '/icons/ggd',
  TeamfightTactics: '/icons/tft',
  Valorant: '/icons/valorant',
  WorldOfTanks: '/icons/wot',
  ApexLegends: '/icons/apexlegends',
  CSGO: '/icons/csgo',
  Fortnite: '/icons/fortnite',
  GTA5: '/icons/gta',
  LeagueOfLegends: '/icons/league-of-legends',
  Minecraft: '/icons/minecraft',
  // TODO: wow cl icon
  LostArkEn: '/icons/lostark',
  FIFA21: '',
  Chess: '',
  CodeNames: '',
  AmongUs: '',
  RocketLeague: '',
  Custom: '/icons/custom',
  PathOfExile: '',
  Warzone: '/icons/warzone',
  LostArk: '/icons/lostark',
  WorldOfWarcraftClassic: '',
  ShadowFight3: '/icons/shadow-fight-3',
  ShadowFight4Arena: '/icons/shadow-fight-4',
  DawnOfZombiesSurvival: '/icons/sawn-of-zombies-survival',
  Crossfire: '/icons/crossout-mobile',
  NewStateMobile: '/icons/new-state-mobile',
  RiseOfEmpires: '/icons/rise-of-empires',
  ModernStrikeOnlinePvPFPS: '/icons/modern-strike-online-pvpfps',
  RiseOfKingdomsLostCrusade: '/icons/rise-of-kingdoms-lost-crusade',
  IdleHeroes: '/icons/idle-heroes',
  LastDayOnEarthSurvival: '/icons/last-day-on-earth-survival',
  MobileLegendsAdventure: '/icons/mobile-legends-adventure',
  STALKER2HeartOfChornobyl: '/icons/stalker-2-heart-of-chornobyl',
  Highrise: '/icons/highrise',
  AFKArena: '/icons/afk-arena',
};
